<template>
  <v-container fluid>
    <WelcomeMessageEditor
      :content.sync="message"
      :message-title.sync="messageTitle"
      :show-editor.sync="showEditor"
      :editing="itemEditIndex"
      @reset-hide-editor="resetAndHideEditor"
      @save-message="postWelcomeMessage"
      @update-message="updateMessage"
    ></WelcomeMessageEditor>

    <SettingsWelcomeMessagesTable
      :messages="messages"
      :active-message="activeMessage"
      :loading="loading"
      @delete-message="deleteMessage"
      @edit-message="editMessage"
    ></SettingsWelcomeMessagesTable>
  </v-container>
</template>

<script>
import WelcomeMessageEditor from '@/components/SettingsWelcomeMessageEditor.vue';
// eslint-disable-next-line vue/max-len
import SettingsWelcomeMessagesTable from '@/components/SettingsWelcomeMessagesTable.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'WelcomeMessageSettingsComponent',
  components: {
    WelcomeMessageEditor,
    SettingsWelcomeMessagesTable,
  },
  data() {
    return {
      // Editor message
      message: '',
      messageTitle: '',
      showEditor: false,
      itemEditIndex: -1,
    };
  },

  computed: {
    ...mapGetters('welcome_messages', {
      messages: 'allMessages',
      activeMessage: 'activeMessage',
      loading: 'loadingMessages',
    }),
  },

  created() {
    this.loadMessages();
    this.loadActiveMessage(this.$store.state.employeeData.company.id);
  },

  methods: {
    ...mapActions('welcome_messages', {
      loadMessages: 'loadMessages',
      loadActiveMessage: 'loadActiveMessage',
      createMessage: 'createMessage',
      updateMessageContent: 'updateMessage',
      removeMessage: 'delMessage',
    }),

    editMessage(item) {
      this.message = item.description;
      this.messageTitle = item.title;
      this.itemEditIndex = item.id;
      this.showEditor = true;
    },

    resetAndHideEditor() {
      this.showEditor = false;
      this.resetEditor();
    },

    resetEditor() {
      this.message = '';
      this.messageTitle = '';
      this.itemEditIndex = -1;
    },

    postWelcomeMessage(payload) {
      this.$emit('show-feedback', {
        status: 'submitting',
        message: this.$t('submitting'),
      });
      let company_id = this.$store.state.employeeData.company.id;

      const data = {
        company: company_id,
        title: payload.title,
        description: payload.message,
        published: payload.publish ? true : false,
      };

      this.createMessage(data)
        .then(() => {
          this.$emit('show-feedback', {
            status: 'success',
            message: data.published
              ? this.$t('messageSavedAndPUblished')
              : this.$t('messageSavedAsDraft'),
          });
          this.resetAndHideEditor();
        })
        .catch(() => {
          this.$emit('show-feedback', {
            status: 'fail',
            message: this.$t('somethingWentWrong'),
          });
        });
    },

    updateMessage(payload) {
      let feedback = {
        status: 'submitting',
        message: this.$t('savingText'),
      };

      this.$emit('show-feedback', feedback);

      const data = {
        id: payload.messageId,
        data: {
          title: payload.title,
          description: payload.message,
          published: payload.publish ? true : false,
        },
      };

      this.updateMessageContent(data)
        .then(() => {
          this.$emit('show-feedback', {
            status: 'success',
            message: this.$t('updated'),
          });
          this.resetAndHideEditor();
        })
        .catch(() => {
          this.$emit('show-feedback', {
            status: 'fail',
            message: this.$t('updateFailedMessage'),
          });
        });
    },

    deleteMessage(item) {
      let feedback = {
        status: 'submitting',
        message: this.$t('deleting'),
      };

      this.$emit('show-feedback', feedback);

      this.removeMessage(item)
        .then(() => {
          this.$emit('show-feedback', {
            status: 'success',
            message: this.$t('deletedSuccessfully'),
          });
        })
        .catch(() => {
          this.$emit('show-feedback', {
            status: 'fail',
            message: this.$t('deletingFailed'),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
